/*MOVE TO CB THEME */
/* We are hosting this file for Oxworks until STROPS-179 is resolved. Do not remove - it will impact their live site */
/*Colours*/
/* Dark Orange:#EC732C */
/* Teal:#4BBDC3 (buttons- view range, trade-center)*/
/* Teal:#006693 */
/* Greyish Blue:#99BFC1 (connect us banner-homepage)*/
/* Light Greyish Blue:#ECF3F3 */
/* Dark Grey 100%:#303030 (header container)*/
/* Dark Grey 80%:#3D3D3D */
/* Dark Grey 50%:#4E4E4E */
/* Light Grey 50%:#E6E6E6 */
/* Light Grey 20%:#F9F9F9 */
/***fonts***/



@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');

body {
  font-family: "din-condensed", sans-serif;
  font-style: normal;
  font-weight: 400;
}

.proxima-nova {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 300;
}

.rollerscript-smooth {
  font-family: "rollerscript-smooth";
  font-style: normal;
  font-weight: 300;
}

.oswald {
  font-family: oswald, sans-serif;
  font-weight: 300;
}

html,
html a {
  -webkit-font-smoothing: antialiased;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
  -moz-osx-font-smoothing: grayscale;
}
body {
  background-color: #fff !important;
  font-family: "proxima-nova";
}
.mm-wrapper #root-wrapper {
  overflow-x: hidden;
}
.header-top .nested-container,
.desktop-nav-container .nested-container {
  background-color: #4e4e4e;
}
::-moz-selection {
  background-color: #f67925;
  color: #fff;
}
::selection {
  background-color: #3d3d3d;
  color: #fff;
}
.header-container,
.header-primary-container,
.footer-top-container,
.footer-primary-container,
.footer-primary-bottom,
#nav .nav-panel-inner {
  background-color: #303030;
  margin: 0px;
}
nav.nav-container {
  background-color: transparent;
}
.header-top-container {
  background: transparent;
}
.header .userName,
.header .userSwitch,
.header .userSwitch a,
.header .welcome-msg,
.footer-primary-container h6,
.form-search .button-search .fa,
.links > li > a,
.module-search.flyout.fly-right .form-search .input-text {
  color: #fff !important;
}
.header-top-container {
  border-bottom: none;
}
.top-header[class*="grid12"] {
  margin-top: -1px;
  margin-bottom: -1px;
}
.top-header p {
  margin: 0;
  font-size: 12px;
  font-family: "proxima-nova";
  line-height: 36px;
  white-space: nowrap;
}
.top-header a,
.top-header a:hover {
  color: #fff;
  text-shadow: 2px 1px 4px rgb(0 0 0 / 10%);
}
.top-header.login-column p {
  background-color: #457d80;
  max-width: 135px;
}
.top-header p span {
  /* text-transform:uppercase;
     */
  padding: 5px 15px;
  border-width: 0 0 0 1px;
  border-style: solid;
  border-color: #707070;
  display: inline-block;
  letter-spacing: 0.5px;
  font-weight: 400;
}
.top-header p span i {
  color: #f67925;
  margin-bottom: 0;
  line-height: 33px;
}
.top-header p span i::before {
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
}
#nested-container-147,
#nested-container-145,
#grid_246 > nav > nav,
#grid_242 > div > div,
#nav.nav-mobile ul.level0 .nav-item.current > a,
#nav.nav-mobile ul.level0 .nav-item > a:hover,
.cms-index-index .nav-regular .nav-item--home > a,
.nav-mobile .nav-item.level0.current > a,
.nav-mobile .nav-item.level0 > a:hover {
  background-color: #4e4e4e;
}
#nav .nav-panel-inner {
  padding: 0;
}
.desktop-nav-container .nested-container {
  position: relative;
}

@media only screen and (min-width: 1200px) {
  .desktop-nav-container ul#nav {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: auto;
  }
}
.main-container.col1-layout {
  padding-top: 160px !important;
}
.desktop-nav-container ul#nav {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
}
.main-container.col1-layout {
  padding-top: 216px !important;
}
/*Header*/
#header {
  background-image: url(https://cdn-5c84bc36-b681cbc1.mysagestore.com/5ec0094a58a6edb7f7709c6774594487/contents/ckfinder/images/Hexagon_Pattern_Large.png);
}
.xm-grid-header > .container {
  max-width: 100%;
}
#mini-cart .feature-icon-hover a,
.module-search,
.nav-regular li.level0 > a > span,
#root-wrapper span.fa {
  color: #fff;
  font-size: 14px;
  font-family: "Oswald", sans-serif;
  font-style: normal;
  font-weight: 300;
  letter-spacing: 1.75px;
  text-shadow: 2px 1px 4px rgb(0 0 0 / 10%);
}
#mini-cart .feature-icon-hover a {
  font-family: "proxima-nova";
  letter-spacing: 0.5px;
}
#mini-cart.open .feature-icon-hover a,
#root-wrapper span.fa.menu-icon.fa-sliders {
  color: #333;
}
#root-wrapper #mini-cart.open .feature-icon-hover a span.fa:before {
  color: #f77925;
}
#mini-cart .actions button {
  margin-right: 5px;
}
#mini-cart .actions button.button.btn-quote.btn-inline {
  margin-bottom: 5px;
}
button.btn-checkout span span {
  font-size: 12px;
}
small.userSwitch a {
  text-indent: -9999px;
  display: block;
  line-height: normal;
}
small.userSwitch a::after {
  text-indent: 0;
  content: "(switch)";
  float: left;
  text-transform: capitalize;
}
.main-header-container {
  padding-top: 20px;
  padding-bottom: 20px;
}
.main-header-container .link-logout,
.main-header-container .link-login {
  display: none;
}
#nav .nav-panel--dropdown {
  background-color: #303030;
  color: #fff;
  font-family: "Oswald";
  font-style: normal;
  font-weight: 250;
  font-size: 14px;
}
.module-sub-categories div.nav-item > .module-sub-categories-title {
  font-size: 12px !important;
  letter-spacing: 1.75px;
  padding: 0 !important;
}
#nav > li.nav-item.level0.level-top.nav-item--only-subcategories > a:hover {
  background-color: #f67925;
}
.main {
  padding: 0;
}
.header-container {
  border-bottom: 3px solid #f67925;
}
.nav-regular li.level0 > a {
  line-height: 40px;
}
.nav-item.level0 {
  font-size: 14px;
}
.nav-mega-text {
  color: #fff;
}
.top-header {
  color: #ffffff;
  margin-top: 10px;
}
.header-alert {
  text-transform: capitalize;
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin: auto;
  color: #fff;
  background-color: #f67925;
  text-align: center;
  padding-top: 5px;
  max-height: 2em;
  width: 100%;
  margin-top: -5px;
  display: none;
}
.header-top2 {
  max-height: 5em;
}
.icon.icon-hover.fa.fa-star,
.link-wishlist .fa-star::before {
  color: #303030;
  background-color: transparent;
}
.icon.icon-hover.fa.fa-star:hover {
  color: #ec732c;
}
.header .logo {
  max-width: 200px;
}
.logo-margin {
  margin-top: -8px;
}
@media only screen and (max-width: 959px) {
  .logo img {
    max-height: 28px;
  }
}
.mobile-logo-column .header .logo {
  width: 100%;
  padding: 10px 0;
}
.mobile-user-link-column .header-top-container.hide-below-960 {
  display: block !important;
}
.mobile-user-link-column .link-logout,
.mobile-user-link-column .link-admin {
  display: none;
}
.mobile-user-link-column .links > li > a {
  line-height: 70px;
}
.mobile-user-link-column .header .item-right.item.item-user-links {
  margin: 0;
}
.mobile-user-link-column .item.item-min-cart.item-right {
  margin: 0;
  float: right;
}
.mobile-user-link-column #mini-cart .feature-icon-hover {
  padding: 0;
}
.mobile-user-link-column #mini-cart .feature-icon-hover .fa-shopping-cart {
  padding: 0;
  line-height: 70px !important;
}
.mobile-cat-column .mobnav-trigger-wrapper {
  display: block !important;
}
.mobile-cat-column .mobnav-trigger-wrapper .trigger-icon {
  margin: auto;
  display: block;
  float: none;
}
.mobile-cat-column .mobnav-trigger .trigger-icon .line {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 5px;
}
.mobile-search-column .module-search .form-search .input-text {
  background-color: #fff;
  color: #000;
  border-color: #fff;
  width: 80%;
  float: left;
  display: inline-block;
}
.mobile-search-column .module-search .form-search .button-search {
  width: 20%;
  position: relative;
  float: left;
  display: inline-block;
}
.mobile-search-column .module-search .form-search .button-search .fa {
  margin: 0;
}
.margin-top-5 {
  margin: 5px 0;
}
.multi-location-wrapper {
  font-weight: 400;
  font-size: 14px;
  /*text-shadow:2px 1px 4px rgb(0 0 0 / 10%);
     //Intercom - 161933000015970 */
  margin: 10px 0;
  margin-left: 0;
}
.header-container .multi-location-wrapper,
.header-container .multi-location-wrapper a {
  color: #fff !important;
}
.header .userCode,
.header .userName,
.header .userSwitch {
  color: #fff;
  font-weight: 400;
  font-size: 1em;
  margin: 6px 0 !important;
  margin-left: 10px !important;
}
.header .userSwitch a {
  color: #fff;
}
.item-welcome-msg {
  margin: 5px 0 !important;
}
.search-wrapper .form-search .input-text {
  color: #fff !important;
}
.mobile-header-container .form-search .button-search .fa,
#root-wrapper .mobile-header-container span.fa.fa-shopping-cart {
  line-height: 52px;
}
.mobile-header-container .mobnav-trigger .trigger-icon {
  margin-top: 15px;
}
.mobile-header-container .mobnav-trigger .trigger-icon .line {
  background-color: #fff;
}
.mobile-header-container .nav-cart .hide-below-960 {
  display: none !important;
}
.mobile-multi-column .header-top-container.hide-below-960 {
  display: block !important;
}
.mobile-multi-column .header-top-container.hide-below-960 .welcome-msg {
  text-align: left;
}
.mobile-multi-column
.header-top-container.hide-below-960
.welcome-msg
.welcome-msg-txt {
  display: none;
}
.mobile-phone-column p {
  line-height: 23px;
  margin: 5px 0;
}
.mobile-phone-column p i.fa-phone {
  color: #f67925;
}
.mm-menu,
.mm-panel,
.mm-navbar {
  background-color: #343434 !important;
}
.mm-listitem:after {
  border-color: #707070;
}
.mm-listitem a,
.mm-navbar__title span {
  color: #fff !important;
}
.mm-listitem a {
  font-weight: bold;
  font-size: 14px;
  background-color: #4e4e4e;
}
.mm-listitem a i.fa.fa-external-link {
  color: #f67925;
}
.mm-btn:after,
.mm-btn:before {
  border-color: #f67925;
}
.nav-mobile li.nav-item,
.nav-mobile .nav-item.level0 > a {
  background-color: #4e4e4e;
  color: #fff;
}
.nav-mobile .nav-item.parent a {
  border-bottom: 1px solid #707070;
}
.nav-mobile .nav-item.parent .opener {
  color: #f67925;
}
.header-quote-column p a {
  white-space: nowrap;
}
.form-search .input-text {
  border-radius: 0;
  border-color: #777;
  color: #fff;
}
.category-menu-module {
  max-width: 100% !important;
}
.category-menu-module .nav-regular > li > a {
  padding: 0px 20px;
}

.mobile-top-container {
  background-color: #4e4e4e;
  border-width: 1px 0;
  border-style: solid;
  border-color: #707070;
}
.mobile-bottom-container {
  background-color: #4e4e4e;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: #707070;
}
.mobile-cat-column .header .item-right {
  margin-top: 0;
}
.mobile-cat-column .header-top {
  text-align: right;
}
#root-wrapper .mobile-cat-column span.fa {
  font-size: 18px;
}
@media only screen and (min-width: 1180px) and (max-width: 1499px) {
  .header-top .grid12-2.spacer {
    width: 6.33% !important;
  }
  .header-top .grid12-6.top-header {
    width: 64.67%;
  }
}
@media only screen and (max-width: 1179px) {
  .spacer {
    display: none;
  }
  .header-top .grid12-2.header-top2 {
    width: 23%;
  }
  .header-top .grid12-6.top-header {
    width: 73%;
  }

  .user-link-column {
    width: 31.33%;
  }
  .header-quote-column {
    width: 14.66%;
    margin-left: 0;
    margin-right: 2%;
  }
}
@media only screen and (min-width: 960px) {
  header#header.floating {
    display: block;
    position: unset !important;
  }
  .header .item-right {
    margin-top: 25px;
  }
  .search-wrapper {
    margin-top: 25px;
    margin-left: 12px;
    margin-bottom: 25px;
  }
  .logo-wrapper {
    margin-top: 20px;
  }
}
@media only screen and (max-width: 959px) {
  .cat-title-column .module-category-misc > .grid12-0:first-of-type {
    max-width: 80%;
  }
  .grid12-1.keep-column-width,
  .keep-column-widths .grid12-1 {
    width: 6.33% !important;
  }
  .grid12-2.keep-column-width,
  .keep-column-widths .grid12-2 {
    width: 14.66% !important;
  }
  .grid12-3.keep-column-width,
  .keep-column-widths .grid12-3 {
    width: 23% !important;
  }
  .grid12-4.keep-column-width,
  .keep-column-widths .grid12-4 {
    width: 31.33% !important;
  }
  .grid12-5.keep-column-width,
  .keep-column-widths .grid12-5 {
    width: 39.67% !important;
  }
  .grid12-6.keep-column-width,
  .keep-column-widths .grid12-6 {
    width: 48% !important;
  }
  .grid12-7.keep-column-width,
  .keep-column-widths .grid12-7 {
    width: 56.33% !important;
  }
  .grid12-8.keep-column-width,
  .keep-column-widths .grid12-8 {
    width: 64.67% !important;
  }
  .grid12-9.keep-column-width,
  .keep-column-widths .grid12-9 {
    width: 73% !important;
  }
  .grid12-10.keep-column-width,
  .keep-column-widths .grid12-10 {
    width: 81.33% !important;
  }
  .grid12-11.keep-column-width,
  .keep-column-widths .grid12-11 {
    width: 89.67% !important;
  }
  .grid12-12.keep-column-width,
  .keep-column-widths .grid12-12 {
    width: 98% !important;
  }
  #header .grid12-1,
  #header .grid12-2,
  #header .grid12-3,
  #header .grid12-4,
  #header .grid12-5,
  #header .grid12-6,
  #header .grid12-7,
  #header .grid12-8,
  #header .grid12-9,
  #header .grid12-10,
  #header .grid12-11,
  #header .grid12-12:not(.no-gutter),
  div[class*="grid12-"].keep-column-width:not(.no-gutter),
  .keep-column-widths div[class*="grid12-"]:not(.no-gutter) {
    margin-left: 1%;
    margin-right: 1%;
  }
  #header .grid12-1:not(.right),
  #header .grid12-2:not(.right),
  #header .grid12-3:not(.right),
  #header .grid12-4:not(.right),
  #header .grid12-5:not(.right),
  #header .grid12-6:not(.right),
  #header .grid12-7:not(.right),
  #header .grid12-8:not(.right),
  #header .grid12-9:not(.right),
  #header .grid12-10:not(.right),
  #header .grid12-11:not(.right),
  #header .grid12-12:not(.right) {
    float: left;
  }
  .grid-full,
  .grid12-12 {
    overflow: hidden;
  }
  .header.logo-wrapper > a.logo {
    width: 100%;
    margin-top: 5px !important;
  }
  .mobile-cat-column .header-top-container.hide-below-960 {
    display: block !important;
  }
}
@media only screen and (max-width: 399px) {
  .mobile-header-container .nav-logo {
    width: 50%;
  }
}
#multi-location-default-location {
  color: #fff;
}
#module-switch-location-link {
  color: #f67925;
  text-decoration: underline;
}
/** Center the Menu **/
#nav {
  text-align: center;
}
#mini-cart
> div.dropdown-toggle.cover.left-hand.opener
> div
> a
> div.hide-below-960 {
  display: none;
}
#nav .nav-panel--dropdown a {
  color: #fff;
  margin-top: 2px;
}
.nav-regular .nav-item--parent > a .caret {
  display: none;
}
.module-sub-categories-img img {
  margin-bottom: 5px;
  padding-bottom: 10px;
  border-bottom: 1px solid #fff;
}
.free-quote {
  border: none;
  color: #fff;
  background-color: #f67925;
  padding: 10px 16px;
  display: inline-block;
  font-size: 14px;
  border-radius: 5px;
  text-transform: uppercase;
}
/*User links into icons*/
.links > li > a::after {
  font-family: "fontAwesome";
  text-indent: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
.links .link-logout::after {
  content: "\f08b";
}
.links .link-login::after {
  content: "\f090";
}
.links .link-register::after {
  content: "\f007";
}
.links .link-admin::after {
  content: "\f013";
}
.links .link-account::after {
  content: "\f007";
}
.links .link-wishlist::after {
  content: "\f004";
}
#root-wrapper span.fa.fa-shopping-cart {
  font-size: 18px;
}
i.fa {
  font-style: normal;
}
.link-wishlist .fa-star::before {
  content: "\f004";
}
.links > li > a {
  text-indent: -9999px;
  position: relative;
  font-size: 18px;
  padding: 0 20px;
  text-align: center;
  display: block;
  color: #fff !important;
}
.links > li,
.links > li > a {
  line-height: 2em;
}
#root-wrapper span.fa {
  padding: 0.4em;
}
#nav .mega .nav-panel--dropdown {
  /*left:0 !important;
     */
  top: 54px !important;
  width: 100vw !important;
  padding-top: 20px;
  padding-bottom: 20px;
}
.nav-regular .mega > .nav-panel--dropdown,
.nav-regular li.level0 > .nav-panel--dropdown {
  margin-left: 0;
}

.nav-regular .nav-item.level0.active > a,
.nav-regular .nav-item.level0:hover > a,
#nav.nav-regular > li:hover,
.nav-item.level0.active {
  background-color: #f67925;
}
.nav-panel--dropdown .nav-item.active {
  background-color: transparent;
}
#nav.nav-regular > li.parent:hover::after {
  content: "\f0dd";
  font-family: fontAwesome;
  color: #f67925;
  position: absolute;
  font-size: 40px;
  top: 40px;
  z-index: 2001;
}
#nav.nav-regular > li.parent.mega:hover::after {
  margin-left: 50px;
}
.nav-item.active {
  background-color: transparent;
}
#root-wrapper span.fa.fa-home {
  padding: 0;
  line-height: 0;
  background-color: transparent;
}
a.view-range {
  font-size: 16px;
  letter-spacing: 0.5px;
}
.view-range:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.get-inspired .nested-container {
  background-color: #99bfc1;
  padding: 0px 0px;
  letter-spacing: 2px;
  margin-top: 4%;
  color: #fff;
}
.get-inspired .nested-container p,
.get-inspired .nested-container p span {
  font-family: "rollerscript-smooth" !important;
}
.learn-more {
  border: none;
  color: #fff;
  background-color: #f67925;
  padding: 10px 16px;
  display: inline-block;
  font-size: 16px;
  border-radius: 5px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  position: absolute;
  top: auto;
  left: 300%;
  bottom: -40%;
}
.section-title.padding-right {
  padding-right: 0;
  font-size: 36px;
  letter-spacing: 2px;
  border: none;
  margin-top: 10px;
  font-family: "rollerscript-smooth";
  text-transform: none;
}
.grey-background .nested-container {
  background-color: #f9f9f9;
  border-top: 1px solid #aaa;
  border-bottom: 1px solid #aaa;
  padding-top: 0 !important;
}
.grey-popout-background {
  background-color: #f3f3f3;
  padding-top: 1%;
  padding-bottom: 1%;
}
.home-icons {
  width: 50px;
  height: 50px;
  display: inline;
  padding: 10px 0;
}
.home-icons-text {
  display: inline;
  padding: 10px 20px;
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  position: relative;
  top: 5px;
  line-height: 1.2px;
}
a.view-range {
  color: #fff;
}
.pool-fencing {
  background-color: #f9f9f9;
  padding: 10px;
}
.pool-fencing h1 {
  letter-spacing: 2px;
}
.getaquote {
  color: #fff;
  font-size: 13px;
  margin-top: 25px;
  border: 1px solid #fff;
  border-radius: 0px;
}
.learn-more-blue {
  border: 1px solid #4bbdc3;
  color: #4bbdc3;
  margin-bottom: 8%;
  letter-spacing: 2px;
  font-size: 16px;
}
.learn-more-blue,
.getaquote {
  background-color: transparent;
  display: inline-block;
  border-radius: 0px;
  text-transform: uppercase;
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 300;
  padding: 10px 10px;
}
a.learn-more-blue {
  color: #4bbdc3;
}
/*Cat page*/
.catpage-black-banner {
  background-color: #000;
  color: #fff;
}
button.button.button.btn-cart.add-cart-button span,
.button.more-info span,
.product-view .btn-cart:hover span,
button.btn-checkout:hover span,
button.button:hover span,
button.button span {
  border-radius: 0;
}
.product-shop button.add-cart-button span:hover,
.product-shop button.add-cart-button:hover span {
  background-color: #f67925 !important;
}
button.btn-checkout:hover span,
button.button span,
button.button span span {
  color: #fff !important;
  background-color: #f67925 !important;
  font-family: "proxima-nova";
  font-weight: 400;
  text-transform: none;
  font-size: 14px;
  letter-spacing: 0.5px;
  transition: 0.3s;
  padding: 0px 15px;
}
button.button:hover {
  opacity: 0.8;
}
.button.more-info span,
.button.more-info span:hover {
  color: #4e4e4e;
  background-color: #fff;
  border: 1px solid #4e4e4e;
}
.price-box .minimal-price .price,
.price-box .regular-price .price,
.price-box-bundle .full-product-price .price {
  font-weight: 600;
  font-family: "proxima-nova" !important;
}
h2.product-name,
h3.product-name {
  text-transform: none !important;
  font-weight: 500 !important;
  font-family: "proxima-nova" !important;
  letter-spacing: 0.25px;
}
h3.product-name {
  font-size: 1.4em !important;
  padding-top: 10px;
}
.breadcrumb-style {
  z-index: 1000;
  padding-top: 10px;
  padding-bottom: 10px;
}
.breadcrumbs li {
  font-size: 12px;
}
.breadcrumbs li:last-of-type {
  font-weight: bold;
  color: #333;
}
.breadcrumbs li span.breadcrumb-separator {
  background: none;
  width: auto;
  height: auto;
  margin: 0 5px 0 7px;
  float: left;
}
.breadcrumbs li span.breadcrumb-separator::after {
  content: "\f105";
  font-family: fontAwesome;
  float: left;
  text-indent: 0;
  color: #333;
}
.breadcrumbs a span {
  color: #333;
  text-transform: none;
  font-family: "proxima-nova";
  font-size: 1em;
}
.pagination-bottom {
  clear: none !important;
}
/*Product Pages*/
/*dropdown overide styling start*/
.variant-opt-img-wrapperÂ {
  font-size: 16px !important;
  font-family: "proxima-nova" !important;
}
.variant-opt-img-wrapper .variant-opt-img {
  max-height: 40px !important;
}
.variant-opt-img-wrapperÂ {
  width: 100% !important;
  max-height: 40px !important;
}
.select2-results__option[aria-selected]Â {
  font-size: 16px !important;
  font-family: "proxima-nova" !important;
  line-height: 40px !important;
  height: 40px !important;
}
.select2-container--default
.select2-results__option--highlighted[aria-selected] {
  background-color: #f67925 !important;
}
.select2-container--default
.select2-selection--single
.select2-selection__arrow {
  height: 40px !important;
}
.select2-container .select2-selection--single {
  height: 40px !important;
}
.select2-container--default
.select2-selection--single
.select2-selection__rendered {
  line-height: 40px !important;
  font-size: 16px !important;
}
.variant-opt-img-wrapper {
  max-height: 40px !important;
  line-height: 40px !important;
  font-size: 16px !important;
  font-family: "proxima-nova" !important;
}
.select2-results__option {
  font-size: 16px !important;
  font-family: "proxima-nova" !important;
  line-height: 40px !important;
  height: 40px !important;
}
/*dropdown overide styling end*/
.module-product-details .product-name h4 {
  display: none;
}
#zoom-btn {
  display: none;
}
.col1-layout .col-main.top-spacer .nested-container {
  padding: 0;
}
.details-main-container .xm-grid-product .breadcrumbs {
  display: block;
}
.xm-grid-product .gen-tabs-style1 {
  max-width: 1200px;
  margin: 0 auto;
}
.xm-grid-product .breadcrumbs-container .nested-container {
  background-color: #e6e6e6;
  padding-bottom: 5px;
}
.xm-grid-product .product-image img {
  border: 1px solid #d8d8d8;
}
@media only screen and (max-width: 959px) {
  .add-cart-column,
  .price-column,
  .qty-box-column {
    width: 100% !important;
  }
  .qty-box-column input.qty-box.input-text {
    width: 80% !important;
    max-width: 200px;
  }
  .img-box .product-image {
    width: 60.4651%;
    margin: 0 auto !important;
  }
  .module-product-details {
    padding: 0 4%;
    box-sizing: border-box;
  }
  .gen-tabs-style1 .tabs-panels {
    padding-left: 0 !important;
  }
  .gen-tabs-style1 .tabs-panels h2,
  .gen-tabs-style1 .tabs-panels .panel {
    padding: 0 4% !important;
    box-sizing: border-box;
  }
  .details-main-container .breadcrumbs {
    display: none !important;
  }
}
/*Removing product name hide on variant swap as per Luke's request 26/03/21*/
/*.xm-grid-product .product-name h4,*/
.availability-only.qohCon,
.product-shop .add-to-links .span-title {
  display: none;
}
.product-shop .short-description {
  font-size: 14px;
  font-weight: inherit;
  padding-top: 5px;
}
.availability.stockStatusCon {
  float: none;
  text-align: left;
  font-weight: 700;
  font-size: 16px;
  position: relative;
  font-family: "proxima-nova";
  font-style: normal;
}
#product-options-wrapper {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #d8d8d8;
}
div#product-options-wrapper dl {
  position: relative;
}
div#product-options-wrapper dd,
div#product-options-wrapper dt {
  padding: 10px 0;
}
.product-code-container {
  position: relative;
}
div#product-options-wrapper dt label,
.product-code-label {
  font-weight: 700;
  font-size: 16px;
  font-family: "proxima-nova";
}
.product-shop .product-type-data {
  text-align: left;
  margin-top: 0;
}
@media only screen and (max-width: 960px) {
  .product-shop,
  .product-shop h1 {
    text-align: left !important;
  }
}
.product-shop .price-box .regular-price .price {
  font-weight: bold;
  font-size: 1.7em;
  font-family: "proxima-nova";
  font-style: normal;
}
.product-shop button.button span {
  text-transform: none;
}
.product-shop .price-box {
  line-height: 45px;
}
.product-shop .qty-wrapper .fa {
  font-size: 20px;
  color: #f77925;
  margin: 0 5px;
  cursor: pointer;
  line-height: normal;
  display: inline-block;
  vertical-align: middle;
  width: 20px;
}
.product-shop .qty-wrapper input {
  color: #333;
  font-weight: bold;
  min-width: auto;
  margin: auto;
  font-family: "proxima-nova";
  font-style: normal;
}
.product-shop .add-to-links {
  list-style: none;
}
.product-shop .add-to-links .feature {
  margin: 0;
  line-height: 45px;
  height: 43px;
  width: 43px;
  border: 1px solid #f77925;
}
.product-shop .add-to-links .feature:hover {
  background-color: #f77925;
}
#root-wrapper .product-shop .add-to-links .feature span span.fa-star.fa {
  background-image: url(https://cdn-5c84bc36-b681cbc1.mysagestore.com/5ec0094a58a6edb7f7709c6774594487/contents/ckfinder/images/Home-Page-Images/cart-icon-orange.png);
  width: 100%;
  height: 100%;
  padding: 0;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  float: none;
}
#root-wrapper .product-shop .add-to-links .feature:hover span span.fa-star.fa {
  background-image: url(https://cdn-5c84bc36-b681cbc1.mysagestore.com/5ec0094a58a6edb7f7709c6774594487/contents/ckfinder/images/Home-Page-Images/Heart-_icon.png);
  background-size: 90%;
}
.feature-icon-hover:hover span.icon.icon-color-productview {
  background-color: transparent;
}
.product-shop .add-to-links .feature span span.fa-star:before {
  content: ".";
  color: transparent;
}
span.icon.icon-color-productview {
  background-color: transparent;
}
.warranty-info {
  background-color: #307585;
  color: #fff;
  padding: 10px 16px;
  display: inline-block;
  font-size: 16px;
  border: 0;
}
.gen-tabs .tabs > li {
  width: calc(25% - 5px);
  margin-right: 5px;
}
.gen-tabs .tabs > li:last-of-type {
  margin-right: 0;
  width: 25%;
}
.gen-tabs .tabs a {
  margin: 0;
  padding: 0;
  background-color: #d8d8d8;
  text-transform: capitalize;
  color: #4e4e4e;
  text-align: center;
  font-size: 14px;
  font-family: "proxima-nova";
}
.gen-tabs-style1 .tabs a.current {
  background-color: #fff;
  border: none;
  margin: 0;
  padding: 0;
}
.gen-tabs .tabs a.current {
  background-color: #ec732c;
  color: #fff;
  font-weight: 400;
}
.gen-tabs .tabs-panels,
.oxworks-icons {
  background-color: #f8f8f8;
}
.gen-tabs-style1 .tabs-panels {
  background-color: #fff;
  padding: 20px;
  border-top: none;
}
.sidebar .block-content #filter_list_box dd ol {
  max-height: none;
}
#mm-filter_list_box dt {
  margin-bottom: 15px;
  margin-top: 25px;
}
#filter_list_box dt {
  margin-bottom: 0;
  padding-bottom: 30px;
  padding-bottom: 30px;
  padding-left: 1em;
  padding-top: 20px;
  padding-right: 20px;
}
#filter_list_box dd {
  margin-bottom: 20px;
  padding-bottom: 20px;
}
#filter_list_box dt,
#filter_list_box dd {
  background-color: #e6e6e6;
  font-weight: bold;
}
#filter_list_box dd li {
  margin-bottom: 15px;
  font-family: "proxima-nova";
}
/*Filter Title Spacing Mobile - OXW Sheppard 21/05/27)*/
@media only screen and (min-width: 960px) {
  .mm-filter_list_box dt {
    margin-bottom: 10px;
    margin-top: 30px;
  }
}
.add-cart-fixed {
  background-color: #ec732c;
  z-index: 1000;
}
@media only screen and (max-width: 1400px) {
  .gen-tabs .tabs a {
    padding: 0 30px;
    line-height: 1.6 !important;
  }
}
@media only screen and (min-width: 960px) {
  .product-shop .qty-wrapper input {
    width: 60px !important;
  }
}
/**Footer**/
#subscribe-form {
  margin-top: 0;
  padding-top: 0;
}
.newsletter-wrapper,
#subscribe-form form {
  background-color: transparent;
}
.newsletter-wrapper .text_title {
  padding-top: 0;
  padding-left: 0;
}
#subscribe-form form {
  text-align: left;
}
#subscribe-form label.newsletter-label-input_name {
  margin-bottom: 20px;
  display: block;
}
#subscribe-form .input-box.newsletter-field-input_name:first-of-type {
  display: none;
}
#subscribe-form .input-box.newsletter-field-input_email {
  margin-left: 0;
}
#subscribe-form .input-box.newsletter-field-input_email input {
  border-radius: 0;
}
#subscribe-form button.button.newsletter-button span {
  background-color: #f67925;
  color: #fff;
}
#subscribe-form button.button.newsletter-button span span {
  height: 45px;
  line-height: 45px;
  width: 45px;
  background-image: url(https://cdn-5c84bc36-b681cbc1.mysagestore.com/5ec0094a58a6edb7f7709c6774594487/contents/ckfinder/images/Home-Page-Images/paper-plane.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: transparent;
  padding: 0;
}
#subscribe-form button.button.newsletter-button:hover {
  opacity: 0.8;
}
#scroll-to-top,
#scroll-to-top:hover {
  background-color: #f67925;
}
.copy-right {
  background-color: #e7e7e7;
  max-height: 4em;
  color: #303030;
}
.copy-right p {
  padding-top: 12px;
  font-size: 14px;
}
h6.block-title.heading,
#footer ul li a,
#subscribe-form label {
  color: #f9f9f9;
}
h6.block-title.heading,
.newsletter-wrapper .text_title {
  letter-spacing: 0.5px;
  font-family: "proxima-nova";
  font-size: 15px;
  font-weight: 600;
  font-size: 18px;
}
#footer ul li a,
#subscribe-form label {
  font-weight: 200;
  font-family: "proxima-nova";
  font-size: 12px;
}
#subscribe-form label {
  line-height: 1.75em;
  text-transform: none;
}
.footer-primary-bottom-spacing {
  border-top: 0;
  border-color: transparent;
  padding-top: 0;
  padding-bottom: 0;
}
.footer-bottom-container {
  background-color: #fff;
}
.footer-bottom {
  padding-top: 0px;
}
.footer-logo {
  border-bottom: 1px solid #707070;
}
.quote-footer-container {
  background-color: #fff;
}
.quote-footer-container .section {
  padding: 0;
}
.footer-top-container,
.quote-footer-container img:hover {
  opacity: 1;
}
@media only screen and (max-width: 959px) {
  .main-container.col1-layout {
    padding-top: 94px !important;
  }
}
/*Category controls and Breadcrumb*/
.cat-control-container .nested-container {
  background-color: #e6e6e6;
}
/**Category Page Filters**/
.block-vertnav .block-content {
  padding-left: 0;
  padding-right: 20px;
  padding-top: 0;
}
.fa-angle-down:before {
  color: #22262a;
}
li.item {
  background-color: #fff;
}
.desc.std:hover {
  display: none;
}
.products-grid.centered .item .actions.add-cart-wrapper button {
  width: 90%;
  margin: 0 5%;
}
.products-grid.centered .item .actions.add-cart-wrapper .button.more-info {
  border: 2px solid #4e4e4e;
}
.products-grid.centered .item .actions.add-cart-wrapper .button.btn-cart {
  /* border:2px solid #f67925;
     */
}
.products-grid.centered .item .actions.add-cart-wrapper button span {
  width: 100%;
  padding: 0;
  border: none;
}
button.add-cart-button,
button.button.btn-cart {
  float: right;
}
.item .desc.std a[title*="Quick View"] {
  display: none;
}
@media only screen and (max-width: 999px) {
  .products-grid.centered .item .actions.add-cart-wrapper button {
    float: none;
    display: block;
    width: 95%;
    margin-bottom: 2%;
  }
}
/*Product Page Tab Styling*/
#product-tabs {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 300;
}
#product-tabs h3,
#product-tabs h4 {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 500;
  text-transform: capitalize;
}
#product-tabs h3 {
  font-size: 2em;
  padding-top: 15px;
}
#product-tabs p {
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 100;
  font-size: 16px;
  line-height: 1.4;
}
#product-tabs h4 {
  font-size: 18px;
  padding-top: 20px;
}
.features-list,
.plus-list li {
  line-height: 2.25;
  font-family: "proxima-nova";
  font-style: normal;
  font-weight: 100;
  font-size: 14px;
}
.features-list {
  width: 50px;
  height: 50px;
  display: inline;
  padding: 10px 0;
}
.features-list,
.tick img {
  padding-top: 12px;
  width: 20px;
}
.features-list .feature-text .features-list .feature-text-ss {
  width: auto;
  height: 50px;
  display: inline-block;
}
.features-list .feature-text {
  padding: 0px 20px;
  display: inline-block;
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
}
.features-list .feature-text-ss {
  padding: 10px 10px;
}
#product-tabs tr,
#product-tabs th,
#product-tabs td {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 14px;
  padding: 10px;
  border-bottom: 0.5px solid #d8d8d8;
}
#product-tabs th {
  width: 25%;
  font-weight: 500;
}
#product-tabs td {
  font-weight: 100;
  color: #333;
}
#product-tabs td a {
  color: #ec732c;
  text-decoration: underline;
}
a.buttonwty {
  font-size: 16px;
  letter-spacing: 0.5px;
  color: #fff;
}
.buttonwty:hover {
  opacity: 0.6;
  transition: 0.3s;
}
.buttonwty {
  transition-duration: 0.4s;
  display: inline-block;
  text-align: center;
  padding: 5px 15px;
  background-color: #ec732c;
  color: #fff;
  font-family: "proxima-nova";
  font-size: 16px;
  font-weight: 400;
  border: none;
}
/*Parent Category Pages*/
.category-products-listing .item .product-name a,
.category-products-listing .item .price {
  font-weight: 600;
  font-family: "proxima-nova";
}
.category-products-listing.products-list .item button.button.more-info {
  margin-right: 15px;
  float: right;
}
.category-products-listing.products-list .item button.button.btn-cart {
  max-width: 125px;
}
.category-products-listing.products-list .item button.button.btn-cart span {
  border: 1px solid #f67925;
}
.cat-hero-image {
  left: 11%;
  color: #fff;
  transform: translateY(-135%);
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 25px;
  position: fixed;
  left: 0;
  right: 0;
}
.heading.permanent {
  font-family: "rollerscript-smooth";
  font-style: normal;
  font-weight: 300;
  text-transform: none;
  margin: 0;
  transform: rotate(-2deg);
}
.heading-permanent-black {
  font-family: "rollerscript-smooth";
  font-style: normal;
  font-weight: 300;
  text-transform: none;
  font-size: 1.75vw;
  margin: 0;
  margin-left: -15px;
  transform: rotate(-2deg);
  padding-bottom: 30px;
}
.heading.permanent {
  color: #fff;
}
.main-cat-title,
.sub-cat-title {
  font-family: "Oswald";
  letter-spacing: 2px;
  margin: 0;
  color: #fff;
}
.main-cat-title {
  font-weight: 400;
  font-size: 3vw;
}
.sub-cat-title {
  font-weight: 300;
  font-size: 3vw;
}
.sub-cat-title2 {
  font-family: "Oswald";
  letter-spacing: 2px;
  line-height: 1;
  margin: 0;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
}
.sub-cat-title-caption {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  line-height: 1;
  letter-spacing: 1px;
  padding-top: 10px !important;
  padding-right: 50% !important;
}
@media only screen and (min-width: 960px) {
  .main-cat-title,
  .sub-cat-title {
    line-height: 0.9;
  }
  .heading.permanent {
    font-size: 1.75vw;
    margin-left: -15px;
    padding-bottom: 30px;
  }
  .cat-hero-image {
    max-width: 1140px;
  }
}
@media only screen and (max-width: 959px) {
  .heading.permanent,
  .main-cat-title,
  .sub-cat-title {
    line-height: normal;
    font-size: 6vw;
  }
  .cat-hero-image {
    max-width: 80%;
  }
  .cat-hero-image br {
    display: none;
  }
}
/*home page insights*/
.home-page-get-inspired .page-content {
  padding: 30px 50px;
}
/*Custom Pages*/
h2.parent-category-content,
h2.parent-category-content-subtitle {
  font-family: "Oswald";
  font-size: 40px;
  letter-spacing: 2px !important;
  line-height: 1;
  font-weight: 300;
}
h2.parent-category-content {
  line-height: 1;
}
h2.parent-category-content,
span.parent-category-content {
  font-weight: 400;
}
h2.parent-category-content-subtitle,
span.parent-category-content-subtitle {
  font-weight: 300;
  padding-bottom: 1.5%;
}
.parent-category-content-body,
.parent-category-content-body-semibold,
.parent-category-content-body-tab-title {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 14px;
  line-height: 1.4;
  /* padding-left:20px !important;
     */
  margin: 0 !important;
}
span.parent-category-content-body-semibold,
span.parent-category-content-subtitle,
span.parent-category-content {
  padding-left: 0 !important;
}
.resource-titles {
  border: 1px solid #707070;
  padding: 15px;
}
.resource-titles h2 {
  font-family: "Oswald";
  font-size: 22px;
  letter-spacing: 2px;
  font-weight: 400;
  line-height: 1.4;
}
h3.shop-title {
  padding-left: 10px !important;
}
.parent-category-content-body {
  font-weight: 300;
}
.parent-category-content-body-semibold {
  font-weight: 500;
}
h2.parent-category-content-subtitle-orange {
  font-family: "oswald";
  font-size: 32px;
  letter-spacing: 2px;
  line-height: 1;
  color: #f67925;
  font-weight: 300;
  padding-bottom: 0;
  padding-left: 0px !important;
}
.hr {
  border-top: 1px solid #707070;
}
.parent-category-content-body1 {
  font-weight: 500;
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 16px;
  line-height: 1.5;
}
.shopnow-content,
.colchart-button:hover {
  transition-duration: 0.4s;
  text-align: center;
  background-color: #f67925;
  color: #fff;
  font-family: "proxima-nova";
  font-weight: 400;
  border: none;
  letter-spacing: 0.5px;
}
.shopnow-content {
  display: inline-block;
}
.shopnow-content:hover {
  background: rgba(246, 121, 37, 0.8);
  color: #fff;
}
.view-range,
.trade-center,
a.trade-center {
  transition-duration: 0.4s;
  display: inline-block;
  text-align: center;
  padding: 15px 60px;
  background-color: #457d80;
  color: #fff;
  font-family: "proxima-nova";
  font-size: 16px;
  font-weight: 400;
  border: none;
}
.viewdetails-content-cat:hover {
  background: rgba(255, 255, 255, 0.3);
  color: white;
}
.viewdetails-content,
.viewdetails-content-cat,
.colchart-button,
.viewdetails-content-download {
  font-family: "proxima-nova";
  font-weight: 400;
  letter-spacing: 0.5px;
  font-size: 16px;
  margin: 5px;
}
.viewdetails-content:hover {
  opacity: 0.6 !important;
}
.colchart-button {
  padding: 7px 40px;
}
.viewdetails-content-download,
.colchart-button,
.viewdetails-content {
  border: 1px solid #f67925;
  color: #f67925;
}
.viewdetails-content-download {
  padding: 15px 60px;
}
.viewdetails-content-cat {
  border: 1px solid #fff;
  color: #fff;
}
.shopnow-content:hover,
.view-full-range:hover,
.view-all:hover,
.view-less:hover,
a.view-sg1:hover,
a.view-sg2:hover,
a.view-sg3:hover {
  opacity: 0.8;
  cursor: pointer;
}
.shed-background {
  background-color: #ecf3f3;
}
.gates {
  float: left;
  padding-left: 1%;
  padding-top: 2%;
  padding-right: 1%;
}
.gates-outer {
  display: flex;
}
.gates-caption {
  background-color: #3d3d3d;
  padding: 20px;
  text-align: center;
  font-weight: 400;
  color: #fff;
  font-family: "Oswald";
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 2px;
}
.gates-caption a {
  color: #fff;
}
.gates-caption:link a {
  color: #fff;
}
.gates-caption:visited a {
  color: #fff;
}
.gates-caption:hover a {
  color: #fff;
  transition: 0.3s;
}
.gates-caption:active a {
  color: #fff;
}
.gate-title {
  font-family: "rollerscript-smooth";
  font-style: normal;
  font-weight: 300;
  font-size: 35px;
  transform: rotate(-2deg);
  color: #303030;
  padding-top: 40px;
}
.gate-content {
  background-color: #ecf3f3;
}
.diy-videos h2 {
  font-size: 25px;
}
.download-titles {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 40px;
  line-height: 2;
  margin: 0 !important;
}
.download-titles:before {
  content: "\f107";
  font-family: fontAwesome;
}
.download-titles.active:before {
  content: "\f106";
  font-family: fontAwesome;
}
.download-titles:after,
.download-titles:before {
  color: #303030;
  font-weight: bold;
  margin-right: 10px;
  margin-left: 5px;
}
.download-tab-button:active {
  background-color: #f67925;
  border: none;
  color: #fff;
  padding: 10px 15px;
  font-size: 15px;
  font-family: "proxima-nova";
}
.download-tab-button {
  background-color: transparent;
  border: none;
  padding: 10px 15px;
  font-size: 15px;
  font-family: "proxima-nova";
}
.download-tab-button.tablink.orange {
  background-color: #f67925;
  color: #fff;
}
.download-button,
.download-button-colchart {
  background-color: #f67925;
  padding: 20px;
  border: none;
  color: #fff;
  float: right;
  width: 15%;
  font-family: "proxima-nova";
  font-size: 15px;
}
.download-button-colchart {
  float: none !important;
  width: 60%;
  margin-left: 12px;
  margin-top: 10%;
}
.w3-bar.w3-black {
  border-bottom: 1px solid #e7e7e7;
}
.w3-container-outer {
  display: block;
  background-color: #404040;
  color: #fff;
  float: left;
  width: 100%;
  margin-top: 10px;
}
@media only screen and (min-width: 960px) {
  .viewdetails-content,
  .viewdetails-content-cat,
  .colchart-button,
  .viewdetails-content-download {
    font-size: 16px;
    margin: 5px;
  }
}
.shopnow-content,
.colchart-button:hover {
  font-size: 16px;
  margin-top: 15px;
  padding: 15px 60px;
}
.viewdetails-content,
.viewdetails-content-cat {
  padding: 12px 40px;
}
.category-products-listing.products-list .item .price-box {
  text-align: center;
}
.category-products-listing.products-list .item .price-box .price {
  margin-right: 11%;
  float: right;
}
.shopnow-content {
  padding: 15px 60px;
}
@media only screen and (max-width: 959px) {
  .shopnow-content {
    padding: 5px 15px;
  }
}
/*Laptop resizing*/
@media only screen and (max-width: 1400px) {
  .shopnow-content {
  }
}
.viewdetails-content {
  padding: 12px 40px;
}
/*Category Detail Pages*/
.detail-page-shop-box {
  background-color: #fff;
  text-align: center;
  padding: 30px;
  border: 1px solid #b3b3b3;
  box-shadow: 1px 1px 1px #d4d4d4;
  height: auto;
  -webkit-transition: all 0.3s ease-in-out 0s;
}
@media only screen and (max-width: 960px) {
  .detail-page-shop-box {
    margin: 15px;
  }
}
.detail-page-shop-box:hover {
  opacity: 75%;
  transform: translate(0, -5px);
}
.shop-title,
.shop-title-bold {
  font-size: 22px;
  font-family: "din condensed", sans-serif;
  font-style: normal;
}
.shop-title {
  font-weight: 300;
}
.shop-title-bold {
  font-weight: 500;
}
.detail-page-shop-box .shopnow-content {
  margin-right: 0;
  font-weight: 400;
  padding: 10px 10px;
  margin-top: 30px;
  width: -webkit-fill-available;
  margin: 5px;
}
.click-here {
  color: #f67925;
  text-decoration: underline;
  font-weight: 600;
  margin-left: 10px;
}
.product-downloads tr,
.product-downloads td,
.product-downloads th {
  font-family: "proxima-nova";
  font-weight: 400;
  font-size: 16px;
  padding: 10px;
  border-bottom: 0.5px solid #d8d8d8;
}
.product-downloads th {
  width: 20%;
}
.product-downloads {
  width: 100%;
}
.product-downloads td a {
  color: #f67925;
  text-decoration: underline;
}
.pool-fence-laws-buttons a {
  color: #fff;
}
.pool-fence-laws-buttons {
  float: left;
  width: 29%;
  background-color: #707070;
  color: #fff;
  font-weight: 500;
  font-family: "proxima-nova";
  font-style: normal;
  text-align: center;
  padding: 15px 20px;
  font-size: 16px;
  margin-right: 7px;
  margin-bottom: 6px;
}
.pool-fence-laws-buttons:hover {
  opacity: 50%;
}
.accordion-faq {
  background-color: #fff;
  color: #444;
  cursor: pointer;
  padding: 10px;
  width: 100%;
  border: 1px solid #b3b3b3;
  text-align: left;
  outline: none;
  font-size: 16px;
  transition: 0.4s;
  font-family: "proxima-nova";
  font-weight: 400;
  margin: 5px;
  box-shadow: 1px 1px 1px #d4d4d4;
}
.accordion-faq.active {
  font-weight: bold;
}
.accordion-faq:after,
.accordion-faq:before {
  color: #777;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}
.accordion-faq:before {
  content: "\25BC";
}
.accordion-faq.active:before {
  content: "\25B2";
}
.panel-faq,
.download-tabs {
  padding: 15px 18px;
  background-color: white;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  font-size: 15px;
}
.stock-colors,
.stock-colors-download {
  float: left;
  text-align: center;
}
.stock-colors {
  padding: 15px 5px;
  width: 18%;
  margin-right: 12px;
  margin-bottom: 12px;
}
.stock-colors-download {
  padding: 18px 10px;
  width: 20%;
  margin-right: 18px;
  margin-bottom: 18px;
}
.stock-colors h3,
.stock-colors-download h3 {
  font-weight: 600;
  font-family: "proxima-nova";
  margin: 0;
  text-transform: none !important;
}
.stock-colors h3 {
  font-size: 16px;
}
.stock-colors-download h3 {
  font-size: 14px;
}
.stock-colors p,
.stock-colors-download p {
  font-weight: 100;
  font-family: "proxima-nova";
}
.stock-colors p {
  font-size: 12px;
}
.stock-colors-download p {
  font-size: 10px;
}
.mix-match-color {
  float: right;
  position: relative;
  top: -50px;
}
.panel-faq,
.download-tabs {
  display: none;
}
.view-full-range,
.view-all,
.view-less,
a.view-sg1,
a.view-sg2,
a.view-sg3 {
  display: inline-block;
  padding: 15px;
  background-color: #e5e5e5;
  color: #303030;
  font-family: "rollerscript-smooth";
  font-size: 28px;
  font-weight: 400;
  text-align: center;
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 0px 1px;
}
.laser-buttons,
.laser-buttons-spec {
  display: inline-block;
  color: #fff;
  font-family: "proxima-nova";
  font-size: 15px;
  /* font-weight:700;
     */
  width: 50%;
}
.laser-buttons {
  text-align: left;
}
.laser-buttons:hover,
.laser-buttons-spec:hover {
  color: #fff;
}
.laser-buttons-spec {
  text-align: right;
}
.selection-buttons {
  padding: 10px 10px;
  background-color: #f67925;
  margin-bottom: 10px;
}
.view-sg1,
.view-sg2,
.view-sg3 {
  float: left;
  padding-right: 3%;
  padding-bottom: 2%;
}
.laser-cut-design-selection {
  float: left;
  padding-right: 10px;
}
.laser-cut-design-selection-popout,
.gates-outer-popout {
  display: none;
}
.laser-screen-width.grid12-12 {
  width: 100%;
}
div[class*="view-sg"][class*="popout"] {
  display: none;
  background-color: #f3f3f3;
  padding-top: 10px;
}
.view-sg1-popout::before,
.view-sg2-popout::before,
.view-sg3-popout::before {
  content: "\f0de";
  font-family: "FontAwesome";
  position: absolute;
  color: #f3f3f3;
  font-size: 70px;
  width: 50px;
  line-height: 45px;
  text-indent: 0;
  top: -15px;
}
.view-sg1-popout::before {
  left: 20%;
}
.view-sg2-popout::before {
  left: 50%;
}
.view-sg3-popout::before {
  left: 70%;
}
#nav.nav-regular > li.parent:hover::after {
  content: "\f0dd";
  font-family: fontAwesome;
  color: #f67925;
  position: absolute;
  font-size: 40px;
  top: 40px;
  z-index: 2001;
}

#standard-designs {
  scroll-behavior: smooth;
}
.home-icon-background-balustrade {
  height: 50px;
}
.tube-options {
  font-family: "Oswald";
  letter-spacing: 2px;
  font-size: 25px;
  text-transform: uppercase;
}
.tube-examples,
.tube-image {
  float: left;
  padding-right: 2%;
}
.tube-caption {
  text-align: center;
}
.cart-banner {
  position: absolute;
  top: 10%;
  left: 10%;
  color: #fff;
}
.cart-banner p {
  font-family: "proxima-nova";
  font-size: 15px;
  font-weight: 300;
}
.cart-banner h2 {
  font-family: "Oswald";
  font-weight: 400;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 45px;
}
.img-vl {
  border-right: 4px solid #707070;
  height: 100px;
  margin-right: 20px;
  margin-left: 20px;
}
.img-cb {
  float: right;
}
.panel-profile h2,
.panel-profile h4 {
  font-family: "Oswald";
  font-style: normal;
  letter-spacing: 2px;
}
.panel-profile h2 {
  font-weight: 400;
}
.panel-profile h4 {
  font-weight: 200;
}
.profile-tag,
.profile-tag-3 {
  border-radius: 30px;
  padding: 5px 20px;
  width: fit-content;
  position: relative;
  top: 20px;
}
.profile-tag {
  left: 70px;
}
.profile-tag-3 {
  left: 30%;
}
.profile-tag .features-list .tick img {
  padding-top: 5px;
}
.profile-tag .features-text {
  margin-left: 20px;
}
.slat-system-profile {
  text-align: center;
  background-color: #fff;
  padding: 30px;
  margin-top: 5px;
}
.slat-system-profile .features-list {
  text-align: left;
}
.slat-system-profile .features-list .features-text-ss {
  padding: 0;
}
/* General */
body {
  background-color: #fff !important;
}
a {
  transition: none;
}
.col-main .grid-container > .container,
.container > .col-main.grid12-12.container {
  float: none;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.no-gutter > .grid-full {
  width: 100%;
  margin: 0;
}
#scroll-to-top {
  background-image: none;
}
a#scroll-to-top::before {
  content: "\f106";
  font-family: "FontAwesome";
  position: absolute;
  left: 0;
  color: #fff;
  font-size: 30px;
  width: 50px;
  line-height: 45px;
  text-indent: 0;
}
.fa-angle-down:before {
  content: "\f0dd" !important;
}
.fa-angle-up:before {
  content: "\f0de" !important;
}
.fa-check-square-o,
.fa-square-o {
  background-color: #f3f3f3;
  border-radius: 3px;
  height: 15px;
}
.pager .pages li.current,
.slider-pagination1 .owl-controls .owl-page.active span,
.slider-pagination1 .owl-controls.clickable .owl-page.active:hover span,
.slider-pagination2 .owl-controls .owl-page.active span,
.slider-pagination2 .owl-controls.clickable .owl-page.active:hover span,
.sorter .view-mode span.grid,
.sorter .view-mode span.list {
  background-color: #f67925;
  color: #fff;
}
.pager .pages li a {
  background-color: #303030;
  color: #fff;
}
input.input-text,
select,
textarea {
  /* border-radius:10px;
         */
}
input.input-text {
  height: 45px;
}
.product-shop h1,
.product-shop .short-description {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 2em;
}
.product-shop h1 {
  font-weight: 600;
  /* padding-top:5%;
         */
}
.product-shop .short-description {
  font-weight: 100;
  font-size: 14px;
}
@media only screen and (min-width: 1280px) {
  .col-main .grid-container > .container,
  .container > .col-main.grid12-12.container {
    /*max-width:1200px;
             */
  }
}
@media only screen and (max-width: 1279px) {
  .col-main .grid-container > .container,
  .container > .col-main.grid12-12.container {
    /* max-width:960px;
             */
  }
}
@media only screen and (min-width: 960px) {
  .show-below-960,
  .hide-above-960 {
    display: none !important;
  }
}
@media only screen and (max-width: 959px) {
  .hide-below-960 {
    display: none !important;
  }
}
@media only screen and (max-width: 959px) {
  .grid-col2-main,
  .grid-col2-sidebar,
  .grid-col3-main,
  .grid-col3-sidebar,
  .grid-full,
  .grid12-1,
  .grid12-10,
  .grid12-11,
  .grid12-12,
  .grid12-2,
  .grid12-3,
  .grid12-4,
  .grid12-5,
  .grid12-6,
  .grid12-7,
  .grid12-8,
  .grid12-9 {
    width: 100% !important;
    margin-left: 0;
    margin-right: 0;
  }
}
.h1-title,
.h2-title {
  font-family: "oswald";
  font-style: normal;
  font-weight: 400;
  letter-spacing: 2px;
}
.h1-title {
  font-size: 40px;
}
.h2-title {
  font-size: 30px;
}
p {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 15px;
  line-height: 1.5;
}
@media (max-width: 767px) {
  .modal {
    bottom: auto !important;
  }
  .location-pop-up .multi-location-module-dropdown p:after {
    bottom: auto !important;
    left: 10px !important;
    top: 0 !important;
  }
  .location-pop-up .modal-title {
    background-position-x: -100px;
  }
  .location-pop-up h4.modal-title {
    padding-left: 10px !important;
  }
  .location-pop-up .modal-title::after {
    left: 10px !important;
  }
  .modal-dialog {
    margin: 0px !important;
  }
  .location-pop-up .modal-body {
    padding-left: 10px !important;
  }
}
/*LOCATION POPUP*/
.location-pop-up .modal-header {
  padding: 0;
}
.location-pop-up .modal-title {
  background-image: url("https://cdn-5c84bc36-b681cbc1.mysagestore.com/5ec0094a58a6edb7f7709c6774594487/contents/ckfinder/images/Home-Page-Images/Test/SetYourStore_Pin_BG.png");
  height: 70px;
}
.location-pop-up h4.modal-title {
  padding-left: 120px;
  padding-top: 10px;
  font-family: "rollerscript-smooth";
  font-size: 30px;
  text-transform: none;
  color: #1b262c;
}
.location-pop-up .modal-title::after {
  content: "To view relevant pricing and availability";
  left: 120px;
  position: absolute;
  top: 50px;
  font-size: 16px;
  font-family: "proxima-nova";
  font-weight: 400;
}
.location-pop-up .multi-location-module-dropdown p {
  visibility: hidden;
}
.location-pop-up .multi-location-module-dropdown p:after {
  content: "Please select your closest location:";
  left: 120px;
  position: absolute;
  bottom: 60px;
  color: #000;
  font-family: "proxima-nova";
  visibility: visible;
  display: block;
}
.location-pop-up .modal-body {
  position: relative;
  padding-left: 120px;
  padding-right: 30px;
}
.location-pop-up .multi-location-selection {
  width: 100%;
}
.location-pop-up .modal-header button.close {
  padding: 10px;
}
.hidemodule {
  display: none;
}
/*juan demo selection summary*/
.cfg-selected-image figure {
  border: 1px solid #ddd;
  margin: 5px;
  border-radius: 5px;
}
.configurator-selection-summary p {
  color: #f67925;
  font-weight: 500;
}
.product-shop button.add-cart-button,
.product-shop button.button.btn-cart {
  float: right;
  padding: 0px 60px;
  background-color: #f67925;
}
.configurator-price-con.has-main-items {
  display: none;
}
.main-container.col1-layout {
  padding: 0px !important;
}
.main-container.col1-layout {
  width: 100%;
  margin: 0 auto;
}
.option .left.images {
  display: none;
}
.item-configurator-tabs .option .main {
  width: 100%;
  float: right;
}
.product-view .product-form .grid12-5 {
  width: 56.33%;
}
.product-view .product-img-column.grid12-4.zoom-style {
  width: 39.67%;
}
.product-view .add-to-box,
.product-view .container1-wrapper,
.product-view .container2-wrapper {
  padding: 0;
  margin: 0;
  border-bottom: none;
  display: block;
  float: left;
}
.action-box.clearer {
  width: 20%;
  display: block;
  float: left;
  margin: 0;
}
.select2-container--default .select2-selection--single {
  border: 1px solid #ddd !important;
  border-radius: 5px !important;
}
.module-product-details .variations .swatchesCon.list .swatch {
  height: initial;
  border: 1px solid #ddd;
  border-radius: 5px;
  display: block !important;
}
.module-product-details .variations .swatchesCon.list .swatch .image {
  max-width: 125px;
  max-height: 125px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  display: block;
}
#breadcrumbs a {
  text-transform: initial;
  color: #303030;
}
.details-main-container .breadcrumbs {
  display: block;
  margin: 0;
  padding: 10px 0;
}
.details-main-container .breadcrumbs ul {
  margin: 0;
}
.module-product-details .select2-container {
  width: 100% !important;
}
.module-product-details .variations .swatches-con.list .swatch .image,
.module-product-details .variations .swatchesCon.list .swatch .image {
  width: 100%;
}
.bootstrap-styles .panel,
.gen-tabs-style1 .tabs-panels {
  border: none;
  box-shadow: none;
}
.gen-tabs-style1 .tabs a.current,
.gen-tabs .tabs a {
  margin: 0px 0px 0px 0px;
  padding: 0 60px;
}
.gen-tabs .tabs a {
  font-weight: unset;
}
/** hide preview */
.previewCon {
  display: none !important;
}
.header-top-container {
  background: unset;
}
.innerSwatchText {
  display: block !important;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-family: "proxima-nova";
  color: #3d3d3d;
  font-size: 16px;
  font-weight: 400;
  background: #f3f3f3;
}
.valueLabel {
  display: none;
}
.cfg-selected-variant-image {
  margin-bottom: 15px;
}
.cfg-selected-variant-image img {
  max-width: 110px;
  max-height: 110px;
}
.cfg-selected-image {
  margin-bottom: 15px;
}
.cfg-selected-image img {
  max-width: 110px;
  max-height: 110px;
}
.cfg-selected-variant-image figure {
  border: 1px solid #ddd;
  margin: 5px;
  border-radius: 5px;
}
.module-product-details .add-cart-wrapper {
  padding: 0;
}
.module-product-details .product-code-container,
.module-product-details .product-code-container .product-code-lable,
.module-product-details .product-code-container .product-code {
}
.configurator-set {
  float: left;
  width: 100%;
}
#product-options-wrapper {
  float: left;
  width: 100%;
}
#product-options-wrapper dl,
#product-options-wrapper dt,
#product-options-wrapper dd {
  padding: 0;
  margin: 0;
}
.module-product-details .product-code-container,
#product-options-wrapper dl {
  width: 100%;
}
.module-product-details .product-code-container .product-code-label,
.module-product-details .product-type-data .product-stock-label,
#product-options-wrapper dt {
  float: left;
  width: 40%;
}
.module-product-details .product-code-container .product-code,
.module-product-details .product-type-data .product-stock-status,
#product-options-wrapper dd {
  float: left;
  width: 60%;
}
#product-options-wrapper dt label {
  margin: 0px;
}
/*.xm-grid-page .grid-group:not(:first-child),*/

.xm-grid-header .nested-container {
  max-width: 1600px;
}

.xm-grid-category .grid-group-3,
.xm-grid-page .grid-group .nested-container {
  max-width: 1200px;
}

.xm-grid-header .nested-container,
.xm-grid-category .grid-group-3,
.xm-grid-page .grid-group .nested-container {
  margin: 0 auto;
  width: 100%;
}
.xm-grid-page .grid-group .default-width .nested-container,
.default-width .nested-container {
  max-width: 100%;
  margin: 0;
}
.default-width {
  max-width: 100%;
  margin: 0;
}
.module-category-misc {
  line-height: 46px;
}
.product-search-container {
  width: 100%;
  margin: 0;
}
.breadcrumbs-container {
  margin-bottom: 15px;
}
.breadcrumbs-container .nested-container {
  padding-bottom: 0 !important;
}
.sidebar .block:first-child {
  margin-top: 0;
}
#filter_list_box dt {
  margin-top: 0;
}
.xm-grid-section .page-content img {
  width: 100%;
}
.amount.amount-total {
  line-height: 46px;
  text-align: right;
}
.sort-by select {
  width: 80%;
}
.products-grid.single-line-name .product-name,
.products-grid .product-name,
.products-list .product-name,
h3.product-name {
  overflow: visible;
  white-space: normal;
  text-overflow: unset;
  width: 80%;
  margin: 0 auto;
}
.sorter .view-mode {
  float: right !important;
}
/** hack width for category and product page*/
.product-search-container .xm-grid-category > .grid-group.grid-group-2,
.xm-grid-product > .grid-group.grid-group-2 {
  max-width: 1200px;
  margin: 0 auto;
}
.sidebar .category-filter-collapsible,
.sidebar .filter-collapsible {
  font-size: 24px !important;
  line-height: 0.5em !important;
}
#filter_list_box dt {
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 40px;
}
#filter_list_box dd {
  padding-left: 40px;
}
#filter_list_box dd a {
  font-weight: normal;
}
#filter_list_box i.fa {
  vertical-align: middle;
  line-height: 0.8em;
  margin-bottom: 0;
}
.white-product-page-bg {
  background-color: #fff;
}
.product-image.zoom-inside {
  max-width: 100% !important;
}
.more-images.itemslider-thumbnails {
  max-width: 100%;
  margin: 0 auto;
  padding-top: 20px;
}
.box-additional.box-tabs {
  width: 100%;
  margin: 0;
}
h3.section-title {
  text-align: center;
}
.products-grid .price-box {
  color: #f77925;
  font-weight: 400;
  letter-spacing: 0.5px;
  font-family: Roboto, sans-serif;
  font-size: 18px;
}
.products-grid.size-s .item button.btn-cart span span {
  height: 45px;
  line-height: 45px;
}
.xm-cms-custom-page .breadcrumbs {
  display: none;
}
.padded-container {
  padding: 30px 0;
  width: 95% !important;
  margin-left: 2.5%;
  margin-right: 2.5%;
}
.shop-hero-image {
  /* position:absolute;
         top:50%;
         */
  left: 11%;
  color: #fff;
  transform: translateY(-125%);
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  padding-bottom: 15px;
  position: fixed;
  left: 0;
  right: 0;
}
.shop-hero-image-2 {
  /* position:absolute;
         top:50%;
         */
  left: 11%;
  color: #fff;
  transform: translateY(-115%);
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 40px;
  position: fixed;
  left: 0;
  right: 0;
}
.main-shop-hero-title,
.sub-shop-hero-title {
  font-family: "Oswald";
  letter-spacing: 2px;
  line-height: 0.8;
  margin: 0;
  color: #fff;
}
.main-shop-hero-title {
  font-weight: 400;
  font-size: 2vw;
}
.sub-shop-hero-title {
  font-weight: 300;
  font-size: 2vw;
}
.instock-tile-title {
  font-family: "proxima-nova";
  font-size: 1.8em;
  text-transform: capitalize !important;
  letter-spacing: 0.5px !important;
  font-weight: 700;
  line-height: 1.2;
  color: #3d3d3d;
}
.std address,
.std blockquote,
.std dl,
.std p {
  margin: initial !important;
}
.category-bg-grey {
  background-color: #f9f9f9;
}
.category-bold-paragraph {
  font-weight: 500;
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 14px;
  line-height: 1.4;
  padding-left: 5% !important;
  padding-bottom: 2% !important;
}
.category-normal-paragraph {
  font-family: "proxima-nova";
  font-style: normal;
  font-size: 14px;
  line-height: 1.5;
  padding-bottom: 3% !important;
  padding-left: 5% !important;
}
/*Footer-fix*/
.quote-button {
  border: 1px solid #fff;
  font-weight: 600;
  font-family: "proxima-nova";
}
.quote-button:hover {
  background: rgba(255, 255, 255, 0.3);
}
.quote-button a {
  color: #fff;
}
.about-us {
  text-align: center;
}
.footer-quote-title {
  font-weight: 600;
  font-family: "proxima-nova";
}
.about-us-title {
  font-family: "rollerscript-smooth";
  transform: rotate(-2deg);
}
.footer-quote-text,
.about-us-text {
  font-family: "proxima-nova";
}
.home-carousel .owl-carousel .owl-item img {
  width: 92%;
}
.feature-carousel .owl-carousel .owl-item img {
  width: 92%;
}
.userName {
  display: none !important;
}
.welcome-msg-txt {
  display: none;
}
.welcome-msg:after {
  display: block;
  content: "Hi";
  float: left;
  margin-right: 5px;
}
.status-out,
.stock-out-text,
.status-in,
.stock-in-text {
  float: right;
  width: 60%;
}
.status-in,
.stock-in-text {
  color: #f67925;
}
.status-out,
.stock-out-text {
  color: #1b262c;
}
.bh-sl-container {
  padding: 0;
}
.bh-sl-container .bh-sl-loc-list {
  float: left;
}
.bh-sl-container .bh-sl-map {
  float: right;
}
@media only screen and (min-width: 960px) {
}
.bh-sl-container .form-input label {
  display: none !important;
}
.bh-sl-container button {
  background: #f67925;
  border-radius: 0;
}
.bh-sl-container .form-input input {
  border-radius: 0;
}
.bh-sl-container .bh-sl-loc-list .list-label {
  background: #f67925;
}
.bh-sl-container .bh-sl-form-container {
  border-bottom: solid 1px #ccc;
  margin-top: 0;
  padding: 5px 0;
}
.bh-sl-container .bh-sl-map-container {
  margin-top: 0;
}
.bh-sl-container .bh-sl-map {
  height: 590px;
  margin-top: -60px;
}
#bh-sl-submit .fa {
  font-size: 18px !important;
}
@media only screen and (min-width: 960px) {
  .product-shop .qty-wrapper {
    padding: 0px 25px 0px 25px;
  }
  .quote-button {
    float: left;
    padding: 24px 65px;
    margin-left: 5%;
    font-size: 25px;
    font-weight: 600;
  }
  .footer-primary-container {
    display: none !important;
  }
  .footer-quote {
    float: left;
    margin-left: 10%;
  }
  .footer-quote-title {
    font-size: 25px;
  }
  .about-us-title {
    font-size: 50px;
  }
  .about-us-title p {
    padding: 20px;
  }
  .footer-quote-text,
  .about-us-text {
    font-size: 20px;
  }
}
@media only screen and (max-width: 959px) {
  .product-shop .qty-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: block;
    float: none;
    width: 267px;
  }
  .quote-button {
    max-width: 100px;
    padding: 10px 0;
    display: block;
    margin: 10px auto 0 auto;
  }
  .footer-top-container {
    display: none !important;
  }
  .footer-bottom.footer .section.clearer {
    width: 100% !important;
  }
  .footer-bottom.footer .grid12-4 img {
    float: none !important;
  }
  .page-title h1 {
    font-family: "oswald" !important;
    text-transform: uppercase !important;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: 1px;
  }
}
@media (min-width: 768px) {
  .bh-sl-container .form-input input {
    width: 450px;
  }
}
/*80% opacity on hover - OXW Sheppard 03/21*/
.opacity:hover {
  opacity: 0.8;
  transition: 0.3s;
}
/*dark grey on hover - topheader text - OXW Sheppard 03/21*/
.top-header-hover:hover {
  background-color: #3d3d3d;
  opacity: 0.8;
  transition: 0.3s;
}
/*underline link animation*/
.underlink {
  position: relative;
}
.underlink:before {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #f67925;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.underlink:hover:before {
  visibility: visible;
  width: 100%;
}
.features-list li:before {
  content: "\f058";
  font-family: fontAwesome;
  color: #f67925;
}
.plus-list li:before {
  content: "\f055";
  font-family: fontAwesome;
  color: #f67925;
  padding: 10px;
}
.tick {
  display: inline-block;
}
.tick img {
  display: none;
}
/*resource section titles*/
.download-title {
  font-family: "Oswald";
  font-size: 25px !important;
  letter-spacing: 2px !important;
  font-weight: 400;
}
.download-subtitle {
  font-family: "oswald";
  font-weight: 350;
  font-size: 18px;
  letter-spacing: 2px;
}
div.product-name h4 {
  display: none;
}
.terms-title {
  font-family: "proxima-nova";
  font-size: 25px !important;
  font-weight: 600;
  text-transform: none;
}
.terms-subtitle {
  font-family: "proxima-nova";
  font-size: 18px !important;
  font-weight: 600;
  text-transform: none;
}
/*Product Download*/
.product-download-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding-top: 4%;
}
.product-download-background {
  background-color: #e6e6e6;
  margin-bottom: 1rem;
}
.product-download-background p {
  line-height: 2;
}
.product-dwn table {
  width: 100%;
  line-height: 2;
  font-size: 16px;
  font-family: "proxima-nova";
}
.product-dwn th {
  width: 80%;
  padding: 5px;
}
.product-dwn tr {
  border-bottom: #d8d8d8 solid 1px;
}
.product-dwn td {
  vertical-align: middle;
  text-align: right;
  text-decoration: underline;
}
.add-cart-wrapper {
  padding-bottom: 0;
  margin-bottom: 10px !important;
}
.product-download {
  margin-top: -15%;
}
.resources-filter-title {
  font-family: "proxima-nova";
  text-transform: none;
  font-weight: bold;
  font-size: 1.5em !important;
}
@media only screen and (min-width: 1280px) {
  .grid-col2-sidebar {
    width: 17%;
    margin-top: 1%;
  }
}
/*Account Login Page - OXW Sheppard 25/05/21*/
@media only screen and (min-width: 960px) {
  .account-login.clearer {
    margin-left: 0%;
    margin-top: 2%;
  }
  /*Create an Account Page - OXW Sheppard 25/05/21*/
  .account-create {
    margin-left: 0%;
    margin-top: 2%;
  }
  .buttons-set {
    width: 40%;
  }
  /*Orders - OXW Luke Sheppard 25/05/21*/
  .my-account {
    margin-top: 2%;
    margin-left: 0%;
  }
  .user_contents {
    margin-top: 2%;
    margin-left: 0%;
  }
}
.account-login p.required {
  text-align: left;
}
.block-account li > * {
  text-transform: capitalize;
  font-weight: 500;
  list-style: none !important;
}
.block-account .block content ul {
  list-style: none !important;
}
.account-login .buttons-set a {
  position: relative;
  left: 0%;
}
.account-login {
  position: relative;
  left: 0%;
  margin-top: 20px;
}
/*Home Mobile Optimisation*/
.featured-products-mobile {
  width: 45% !important;
  margin-left: 10px;
}
.multi-location-button span span {
  height: 36px !important;
  line-height: 36px !important;
}
.multi-location-module-dropdown .selection-wrapper {
  width: 350px;
}
.location-pop-up .close {
  display: none;
}
.cart-body {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  margin-top: 20px;
}
.select2-container--default .select2-results > .select2-results__options {
  overflow-y: scroll !important;
  -webkit-overflow-scrolling: touch !important;
}
.nav-item--home {
  display: none !important;
}
.mm-navbars_bottom .mm-navbar {
  background-color: #4e4e4e !important;
}
.mm-navbars_bottom .mm-navbar a {
  color: #fff;
  display: grid;
  margin-top: 10px;
}
.mm-navbars_bottom .mm-navbar .fa {
  color: #f67925;
  font-size: 16px;
}
/* Mobile Menu Icon Margin Adjustment - Sheppard 21/05/27*/
@media only screen and (max-width: 959px) {
  .header .userCode,
  .header .userName,
  .header .userSwitch {
    margin: 1px 20px 0px 0px !important;
  }
}
/*Shop Results Fontsize - Sheppard 21/05/27*/
.amount-total {
  font-size: 1em;
}
.gen-tabs .acctab.current,
.gen-tabs .acctab:focus,
.gen-tabs .acctab:hover {
  color: #ec732c;
}
/*Shop Button Size - Sheppard 21/05/27*/
@media only screen and (max-width: 960px) {
  .products-grid .item button.btn-cart span span {
    padding: 7px 0px !important;
  }
  .products-grid.centered .item .actions.add-cart-wrapper button span {
    width: 94%;
  }
}
.footer-top-container img:hover {
  opacity: 1;
}
.rollerscript-title {
  font-size: 60px;
  font-family: "rollerscript-smooth";
}
@media only screen and (max-width: 960px) {
  .stock-colors {
    width: 45%;
  }
  .stock-colors-download {
    width: 41%;
  }
  .mobile-content {
    width: 100%;
  }
  h2.parent-category-content,
  h2.parent-category-content-subtitle {
    font-size: 30px;
  }
}
.planning-project-rollerscript {
  font-family: "rollerscript-smooth";
  font-size: 25px;
}
.planning-project-button {
  transition-duration: 0.4s;
  text-align: center;
  background-color: #457d80;
  color: #fff;
  font-family: "proxima-nova";
  font-weight: 400;
  border: none;
  letter-spacing: 0.5px;
  margin-left: 20px;
  margin-bottom: 20px;
  padding: 8px 20px;
}
.planning-project-button:hover {
  opacity: 0.8;
  color: #fff;
}
.rollerscript-ksp {
  text-align: center;
  font-size: 30px !important;
  padding-top: 25px;
  color: #f67925;
  text-transform: capitalize;
  font-family: rollerscript-smooth !important;
  transform: rotate(-2deg);
}
.hover-block {
  position: absolute;
  top: 0;
  right: 0;
  width: 90px;
  height: 90px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  background: #f67925;
  border-radius: 100%;
}
#shopping-cart-table .product-name .description {
  font-size: 0.8em;
}
.cart,
.totals-inner,
.panel-body {
  padding: 0px !important;
  background-color: transparent !important;
}
.opc .step-title h2 {
  font-family: "proxima-nova";
}
.data-table tr.odd td,
.data-table tr.odd th {
  border-right: 0px;
}
.product-view .box-tags .form-add button.button span span,
button.button.btn-inline span span {
  padding: 0 5px;
}
h2.installer {
  font-family: "Oswald";
  font-size: 40px;
  letter-spacing: 2px !important;
  line-height: 1;
  font-weight: 400;
}
h3.installer {
  font-weight: 300;
  font-size: 25px;
  line-height: 1.25;
  font-family: "Oswald";
  letter-spacing: 2px;
}
.header .userSwitch a,
.header .userSwitch a:hover {
  border-bottom: none !important;
}
.old-price {
  text-decoration: line-through;
}
.special-price .price {
  color: #f77925 !important;
  font-size: 1.1em;
  font-weight: 600;
}
/* 161933000015970 */
.product-shop.product-shipto .ship-to {
  display: none;
}
.product-shop.module-product-details
.module-multi-location.multi-location-wrapper {
  color: #000;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  margin-left: 0px !important;
}
.product-shop.module-product-details > .grid12-12:nth-child(3) {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d8d8d8;
}
.product-shop.module-product-details a {
  color: #ec732c !important;
}
.product-shop.module-product-details
.module-multi-location.multi-location-wrapper
.product-code-label {
  float: left;
  width: 39%;
  position: relative;
}
.product-shop.module-product-details > div:nth-child(4) {
  margin-top: 20px !important;
}
.product-shop.module-product-details
.module-multi-location.multi-location-wrapper
i {
  color: #f67925;
  padding-right: 15px;
}
.banner-paragraph {
  font-weight: 500;
  font-family: "proxima-nova";
  font-style: normal;
  text-transform: none;
  font-size: 16px;
  color: #fff !important;
  text-shadow: 2px 1px 4px rgba(0, 0, 0, 0.32);
  line-height: 1.5;
}
.sticker.sale {
  background-color: #99bfc1;
}
.sticker {
  width: 75px;
  height: 75px;
  font-size: 1.5em;
  font-weight: 400;
  text-transform: uppercase;
  font-family: "oswald";
}
/* eof MOVE TO CB THEME */
